import React from 'react';
import useDarkMode from 'use-dark-mode';

// import Toggle from 'react-toggle';
import Switch from "react-switch";
// import "react-toggle/style.css"

// import Toggle from './Toggle';

const DarkModeToggle = () => {
  const darkMode = useDarkMode(false);

  return (
    <div style={{textAlign: 'right'}}>
      {/* <button type="button" onClick={darkMode.disable}>
        ☀
      </button> */}
      {/* <Toggle 
        checked={darkMode.value} 
        onChange={darkMode.toggle} 
        icons={{checked: "☀", unchecked: "☾"}}
        /> */}
      <label>
        <Switch 
          onChange={darkMode.toggle} 
          checked={darkMode.value}
          uncheckedIcon={(<span style={{textAlign: 'center', display: 'block'}}>☀</span>)}
          checkedIcon={(<span style={{textAlign: 'center', display: 'block'}}>☾</span>)}
          offColor="#f9f5f1"
          onColor="#132B71"
          offHandleColor="#333" />
      </label>
      {/* <button type="button" onClick={darkMode.enable}>
        ☾
      </button> */}
    </div>
  );
};

export default DarkModeToggle;